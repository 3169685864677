var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-scrollbar',{staticClass:"side-navigation-menu",class:{ 'is-open': _vm.isCollapse },style:(_vm.floatingStyle),on:{"mouseenter":_vm.expandSidebar,"mouseleave":_vm.collapseSidebar}},[_c('el-menu',{ref:"sidebarMenu",class:{
        'default-style': !_vm.brandingInfo.styled_sidemenu,
        'inverted-color-style': _vm.brandingInfo.styled_sidemenu,
      },attrs:{"default-active":_vm.activeLink,"collapse":!_vm.isCollapse,"active-text-color":"#F754A2"}},[_vm._l((_vm.menu),function(sideBarItem,i){return [(sideBarItem.children && sideBarItem.children.length)?[_c('el-submenu',{key:sideBarItem._id,attrs:{"index":sideBarItem._id}},[_c('template',{slot:"title"},[(_vm.getCompanyDetails && _vm.getCompanyDetails.menuIcon)?_c('icons',{attrs:{"iconName":sideBarItem.icon}}):_vm._e(),_vm._v("   "),_c('span',{class:[{ 'submenu': !sideBarItem.enableFullTitle, 'submenu full-title': sideBarItem.enableFullTitle }],attrs:{"title":sideBarItem.title.length > 15 && !sideBarItem.enableFullTitle
              ? sideBarItem.title
              : ''}},[_vm._v(" "+_vm._s(sideBarItem.enableFullTitle ? sideBarItem.title : _vm.$options.filters.truncate(sideBarItem.title, 15))+" ")])],1),_c('el-menu-item-group',[_vm._l((sideBarItem.children),function(sideBarSubItem){return [(sideBarSubItem.type == 'Child')?[(_vm.checkPermissionsStatus(sideBarSubItem))?_c('el-menu-item',{key:sideBarSubItem._id,class:[
                            'custom-icon', 
                           {'dynamic-height': sideBarSubItem.enableFullTitle, 'fixed-height': !sideBarSubItem.enableFullTitle }
                         ],attrs:{"index":sideBarSubItem._id},on:{"click":function($event){return _vm.routeClick(sideBarSubItem, true)}}},[(_vm.getCompanyDetails && _vm.getCompanyDetails.menuIcon)?_c('icons',{attrs:{"iconName":sideBarSubItem.icon}}):_vm._e(),_vm._v("   "),_c('span',{class:[{ 'menu-title': !sideBarSubItem.enableFullTitle, 'menu-title full-title': sideBarSubItem.enableFullTitle }],attrs:{"title":sideBarSubItem.title.length > 11 && !sideBarSubItem.enableFullTitle
                          ? sideBarSubItem.title
                          : ''}},[_vm._v(" "+_vm._s(sideBarSubItem.enableFullTitle ? sideBarSubItem.title : _vm.$options.filters.truncate(sideBarSubItem.title, 11))+" ")])],1):_vm._e()]:_vm._e(),(sideBarSubItem.type == 'SubParent')?[_c('el-submenu',{key:sideBarSubItem._id,class:[
                            'custom-icon', 
                          { 'dynamic-height': sideBarSubItem.enableFullTitle, 'fixed-height': !sideBarSubItem.enableFullTitle }
                      ],attrs:{"index":sideBarSubItem._id}},[_c('template',{slot:"title"},[(_vm.getCompanyDetails && _vm.getCompanyDetails.menuIcon)?_c('icons',{attrs:{"iconName":sideBarSubItem.icon}}):_vm._e(),_vm._v("   "),_c('span',{class:[{ 'menu-title': !sideBarSubItem.enableFullTitle, 'menu-title full-title': sideBarSubItem.enableFullTitle }],attrs:{"title":sideBarSubItem.title.length > 10 && !sideBarSubItem.enableFullTitle
                            ? sideBarSubItem.title
                            : ''}},[_vm._v(_vm._s(sideBarSubItem.enableFullTitle ? sideBarSubItem.title : _vm.$options.filters.truncate(sideBarSubItem.title, 10)))])],1),_c('el-menu-item-group',[_vm._l((sideBarSubItem.children),function(sideBarSubSubItem){return [(_vm.checkPermissionsStatus(sideBarSubSubItem))?_c('el-menu-item',{key:sideBarSubSubItem._id,class:[
                                  'custom-icon', 
                                  { 'dynamic-height': sideBarSubSubItem.enableFullTitle, 'fixed-height': !sideBarSubSubItem.enableFullTitle }
                              ],attrs:{"index":sideBarSubSubItem._id},on:{"click":function($event){return _vm.routeClick(sideBarSubSubItem, true)}}},[(
                              _vm.getCompanyDetails && _vm.getCompanyDetails.menuIcon
                            )?_c('icons',{attrs:{"iconName":sideBarSubSubItem.icon}}):_vm._e(),_vm._v("   "),_c('span',{class:[{ 'menu-title': !sideBarSubSubItem.enableFullTitle, 'menu-title full-title': sideBarSubSubItem.enableFullTitle }],attrs:{"title":sideBarSubSubItem.title.length > 8 && !sideBarSubSubItem.enableFullTitle
                                ? sideBarSubSubItem.title
                                : ''}},[_vm._v(" "+_vm._s(sideBarSubSubItem.enableFullTitle ? sideBarSubSubItem.title : _vm.$options.filters.truncate(sideBarSubSubItem.title, 8))+" ")])],1):_vm._e()]})],2)],2)]:_vm._e()]})],2)],2)]:[(_vm.checkPermissionsStatus(sideBarItem))?_c('el-menu-item',{key:i + sideBarItem._id,class:[
                    'custom-icon', 
                  { 'dynamic-height': sideBarItem.enableFullTitle, 'fixed-height': !sideBarItem.enableFullTitle }
             ],attrs:{"index":sideBarItem._id},on:{"click":function($event){return _vm.routeClick(sideBarItem, false)}}},[(_vm.getCompanyDetails && _vm.getCompanyDetails.menuIcon)?_c('icons',{attrs:{"iconName":sideBarItem.icon}}):_vm._e(),_vm._v("   "),_c('span',{class:[{ 'menu-title': !sideBarItem.enableFullTitle, 'submenu full-title': sideBarItem.enableFullTitle }],attrs:{"title":sideBarItem.title.length > 16 && !sideBarItem.enableFullTitle
              ? sideBarItem.title : ''}},[_vm._v(_vm._s(sideBarItem.enableFullTitle ? sideBarItem.title : _vm.$options.filters.truncate(sideBarItem.title, 16)))])],1):_vm._e()]]}),_vm._l((_vm.configMenu),function(configBarItem,i){return [[_c('el-menu-item',{key:i + configBarItem._id,class:[
                    'custom-icon', 
                    { 'dynamic-height': configBarItem.enableFullTitle, 'fixed-height': !configBarItem.enableFullTitle }
             ],attrs:{"index":configBarItem._id},on:{"click":function($event){return _vm.routeClick(configBarItem, false)}}},[(_vm.getCompanyDetails && _vm.getCompanyDetails.menuIcon)?_c('icons',{attrs:{"iconName":configBarItem.icon}}):_vm._e(),_vm._v("   "),_c('span',{class:[{ 'menu-title': !configBarItem.enableFullTitle, 'menu-title full-title': configBarItem.enableFullTitle }],attrs:{"title":configBarItem.title.length > 16 && !configBarItem.enableFullTitle
              ? configBarItem.title : ''}},[_vm._v(_vm._s(configBarItem.enableFullTitle ? configBarItem.title : _vm.$options.filters.truncate(configBarItem.title, 16)))])],1)]]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }